import { message, Modal, Spin } from 'antd';
import { groupBy, has, sortBy, unionBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import ApiHelper from '../util/ApiHelper';
import DraggableContainer from "./DraggableContainer.js";
import { ImageSelectList } from './ImageSelectList';
import Mapper from './Mapper';
import styles from './Specifications.module.scss';
import './Specifications.scss';
import { Swatch } from "./Swatch";

const SpecificationImage = ({ image = null, updatedImages, availableImages, onImageSelected }) => {
    const [isSelectImageModalVisible, setIsSelectImageModalVisible] = useState(false)
    const [selectedImage, SetSelectdImage] = useState(null)

    const handleSelectImageClick = (event) => {
        showModal()
    }

    const showModal = () => {
        setIsSelectImageModalVisible(true);
    };

    // const handleOk = () => {
    //     setIsSelectImageModalVisible(false);
    //     SetSelectdImage(modalSelectedImage.current)
    //     onImageSelected(modalSelectedImage.current)
    // };

    const handleCancel = () => {
        setIsSelectImageModalVisible(false);
    }

    const handleImageChange = (selectedImages) => {
        const selectedImage = selectedImages[0] ?? null
        setIsSelectImageModalVisible(false);
        SetSelectdImage(selectedImage)
        onImageSelected(selectedImage)
    }

    useEffect(() => {
        SetSelectdImage(image)
    }, [image])

    return (
        <>
            {isSelectImageModalVisible && availableImages && (
                <Modal title="Select Image" width="80%" visible={isSelectImageModalVisible} onCancel={handleCancel}>
                    <ImageSelectList images={availableImages} updatedImages={updatedImages} selectedImages={[]} limit="1" onChange={handleImageChange} />
                </Modal>
            )}
            {selectedImage ? <img src={selectedImage} onClick={handleSelectImageClick} height={60} alt="" /> : <button onClick={handleSelectImageClick}>Select Image</button>}
        </>
    )
}

export const Specifications = (props) => {
    const [selectedSpecifications, SetSelectedSpecifications] = useState(Array.isArray(props.selectedSpecifications) ? props.selectedSpecifications : [])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [linkableSpec, setLinkableSpec] = useState(null)
    const [targetSpecifications, setTargetSpecifications] = useState([])
    //const [specificationsList, setSpecificationsList] = useState(props.specificationList.map((s, i) => ({ ...s, sortOrder: s.sortOrder ?? i })))
    const [specificationsList, setSpecificationsList] = useState([...props.specificationList])
    const saveModalMappingsRef = useRef()
    const [noPrinting, setNoPrinting] = useState(props.noprinting);

    const handleNoPrintingChange = (event) => {
        const isChecked = event.target.checked;
        setNoPrinting(isChecked); // Update the state (asynchronously)
        props.onNoPrinting(isChecked, 'noprinting')
    };



    useEffect(() => {
        setSpecificationsList(structuredClone(props.specificationList))
    }, [props.specificationList])

    const isChecked = (specvalue) => {
        return (selectedSpecifications && selectedSpecifications.some(t => t.id === specvalue.id))
    }

    const findSelectedSpecImage = (specValueId) => {
        let res = null
        if (selectedSpecifications && selectedSpecifications.some(t => t.id === specValueId)) {
            res = selectedSpecifications.find(t => t.id === specValueId).image ?? null
        }
        return res
    }

    const updateSelections = (spec, remove = false) => {
        let selections = [...selectedSpecifications]
        if (remove) {
            selections = selections.filter(t => t.id !== spec.id)
        }
        else {
            selections = [...selections, spec]
        }
        SetSelectedSpecifications(selections)
        props.onSelectionChange(selections, props.specificationType)
    }

    const selectionChanged = (event, specValue) => {
        updateSelections(specValue, !event.target.checked)
    }

    const sortorderChanged = (event, specValue) => {
        let selections = [...selectedSpecifications]
        selections[selections.findIndex(t => t.id === specValue.id)].sortOrder = event.target.valueAsNumber
        SetSelectedSpecifications(selections)
        props.onSelectionChange(selections, props.specificationType)
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        const mappings = saveModalMappingsRef.current.getChangedMappingsRef()
        //Automatically select specification
        //updateSelections(props.specificationList.find(spec => spec.id === Object.keys(mappings)[0]))
        saveMapping(mappings)
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleLinkSpecClick = (event, specvalue) => {
        setTargetSpecifications([])
        setLinkableSpec(specvalue)
        ApiHelper.getCMSSpecs(props.specificationType)
            .then((resspecs) => {
                ApiHelper.getSpecificationMappings(props.supplier, props.specificationType)
                    .then((resmap) => {
                        //Remove cms specs already mapped
                        resmap = Object.values(resmap)
                        //resspecs = resspecs.filter(t => resmap.indexOf(t.id) < 0)
                        setTargetSpecifications(resspecs)
                        showModal()
                    })
            })
    }

    const handleSpecificationImageChange = (selectedImage, specValueId) => {
        const _specs = selectedSpecifications
        if (_specs.some(t => t.id === specValueId)) {
            _specs.find(t => t.id === specValueId).image = selectedImage
        }
    }

    const findSpecImages = (specType, specvalueId) => {
        const items = props.product.items.filter(item => (item[specType] === specvalueId))
        if (items && items.length > 0) {
            return items.flatMap(({ images }) => images).filter(el => el && el.length > 0)
        }
        else {
            return null
        }
    }

    const findUpdatedSpecImages = (specType, specvalueId) => {
        const item = props.product.items.find(item => (item[specType] === specvalueId))
        if (item) {
            return item.updatedImages || {};
        }
        else {
            return {}
        }
    }

    const saveMapping = (newMappings) => {
        ApiHelper.saveSpecificationMappings(props.supplier, props.specificationType, newMappings)
            .then((res) => {
                if (res) {
                    message.success('Specification linked successfully')
                    props.onUpdate(res)
                }
                else {
                    message.error('Unable to link specification')
                }
            })
    }

    // useEffect(() => {
    //     if (Array.isArray(props.selectedSpecifications)) {
    //         SetSelectedSpecifications(props.selectedSpecifications)
    //     }
    // }, [props.selectedSpecifications])

    // useEffect(() => {

    // }, [props.specificationList])

    const sortedSpecificationsList = useMemo(() => {
        //const sorted = sortBy(specificationsList, 'sortOrder')
        // console.log({ sorted });
        if (has(specificationsList[0], 'printingName')) {
            //setSpecificationsList(Object.values(groupBy(specificationsList, 'printingName')))
            return Object.values(groupBy(specificationsList, 'printingName')).sort((a, b) => (a[0].printingName.toUpperCase() < b[0].printingName.toUpperCase() ? -1 : 1))
        }
        else {
            //setSpecificationsList([specificationsList])
            return [specificationsList]
        }
    }, [specificationsList])

    return (
        <>
            {isModalVisible && linkableSpec && (
                <Modal title={linkableSpec.title.nl} width="80%" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} centered>
                    {targetSpecifications.length > 0
                        ? <Mapper ref={saveModalMappingsRef} specificationType={props.specificationType} sourceItems={[linkableSpec]} targetItems={targetSpecifications} mappings={{}} listHeight="400" onSaveChanges={saveMapping} />
                        : <Spin />
                    }
                </Modal>
            )}
            <div className={`${styles.productSpecifications} specfication-${props.specificationType}`}>
                <h3 className={styles.specificationHeading}>
                {props.specificationType == 'printing' && <label className={styles.noPrinting}>
                    <input
                        type="checkbox"
                        checked={noPrinting}
                        onChange={handleNoPrintingChange}
                        value={noPrinting}
                    />
                    No printing
                </label>}
                        {props.specificationType}
                </h3>
                {sortedSpecificationsList.map((speclists, index) => (
                    <div className='specification-values' key={index}>
                        {has(speclists[0], 'printingName') ? <strong>{speclists[0].printingName}</strong> : ''}
                        <div className={styles.specificationList}>
                            {speclists.map(specvalue => (
                                <div key={specvalue.id} className={`${specvalue?.status ? (styles[specvalue.status] ?? '') : (Object.keys(findUpdatedSpecImages(props.specificationType, specvalue.id)).length > 0 ? styles['updated'] : '')} ${styles.specificationMapping}${specvalue.cms ? '' : ' disabled'}`}>
                                    <input type="checkbox" disabled={!specvalue.cms} id={`checkbox--${props.specificationType}--${specvalue.id}`} onChange={e => selectionChanged(e, specvalue)} checked={isChecked(specvalue)} />
                                    <input type="number" disabled={!isChecked(specvalue)} style={{ width: '50px' }} defaultValue={selectedSpecifications.find(t => t.id === specvalue.id)?.sortOrder ?? ''} onChange={e => sortorderChanged(e, specvalue)} />
                                    <label className={styles.checkboxSpec} htmlFor={`checkbox--${props.specificationType}--${specvalue.id}`}>
                                        <div className={styles.text}>
                                            <span className={styles.supplierSpec}>{specvalue.title.nl} </span>
                                            {specvalue.cms && <span className={styles.cmsSpec}>{specvalue.cms.title.nl} </span>}
                                            {props.specificationType === 'color' && (() => {
                                                const stockItem = props.product.items.find(i => i.color === specvalue.id);
                                                return (
                                                    <div className={styles.stockLevel}>
                                                        Stock: {stockItem?.stock?.current}
                                                        {stockItem?.stock?.upcomingStocks?.length > 0 && <h5><strong>Upcoming Stocks</strong></h5>}
                                                        {stockItem?.stock?.upcomingStocks?.map(futureStock => {
                                                            const formattedDate = new Date(futureStock.date).toLocaleDateString('en-US', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric'
                                                            }).replace(/\//g, '.');
                                                            return (
                                                                <div key={futureStock.date}>
                                                                    Stock: {futureStock.quantity}, Date: {formattedDate}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                        {props.specificationType === 'color' && <Swatch size={32} className={styles.swatch} color={specvalue.id} />}
                                    </label>
                                    {isChecked(specvalue) && (props.specificationType === 'color' || props.specificationType === 'size') && <SpecificationImage image={findSelectedSpecImage(specvalue.id)} updatedImages={findUpdatedSpecImages(props.specificationType, specvalue.id)} availableImages={findSpecImages(props.specificationType, specvalue.id)} onImageSelected={(selectedImage) => handleSpecificationImageChange(selectedImage, specvalue.id)} />}
                                    {!specvalue.cms ? <button className="small" onClick={e => handleLinkSpecClick(e, specvalue)}>Link Spec</button> : ''}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}