import { Radio, Space, TreeSelect } from 'antd';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState, useTransition } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { CardSkeleton } from "../skeletons/Skeleton";
import ApiHelper from "../util/ApiHelper";
import { Card } from "./Card";
import { SearchBox } from "./SearchBox";

export const ProductsGrid = ({ id }) => {
    const { supplier, filterStatus } = useParams();
    const [searchQuery, setSearchQuery] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [category, setCategory] = useState(null)
    const [isPending, startTransition] = useTransition()
    const [page, setPage] = useState(1)
    const { loading, error, list, hasNoMore } = useFetch(supplier, searchQuery, category, filterStatus, page);
    const loader = useRef(null)
    const [categories, setCategories] = useState([])
    const [counting, setCounting] = useState(null)
    const navigate = useNavigate()

    const handleChange = (e) => {
        setSearchValue(e.target.value)
        setPage(1)
        debouncedSearchQuery(e.target.value)
        //startTransition(() => setSearchQuery(e.target.value))
    }

    const handleStatusFilterChange = (val) => {
        navigate(generatePath('/supplier/:supplier/:filterStatus?', {
            supplier,
            filterStatus: val
        }))
        setPage(1)
    }

    const debouncedSearchQuery = useRef(debounce((query) => {
        setSearchQuery(query)
    }, 300)).current

    const handleObserver = useCallback((entries) => {
        const target = entries[0]
        if (target.isIntersecting && hasNoMore) {
            setPage((prev) => prev + 1)
        }
    }, [hasNoMore])

    const onCategoryChange = (newCat) => {
        setPage(1)
        setCategory(newCat)
    }

    useEffect(() => {
        if (loading) return
        const option = {
            root: null,
            rootMargin: "20px",
            threshhold: 0.1
        }
        const observer = new IntersectionObserver(handleObserver, option)
        if (loader.current) {
            observer.observe(loader.current)
            loader.current = false
        }
    }, [handleObserver, loading])

    useEffect(() => {
        ApiHelper.getSupplierCategories(supplier)
            .then(data => setCategories(data))
    }, [])
    useEffect(() => {
        ApiHelper.getProductsCounting({supplier,searchValue,categories,filterStatus})
            .then(data => setCounting(data))
    }, [searchValue,categories,filterStatus])
    /*
    const handleItemClick = (event, index) => {
        //Selects click element, or unselects when same element clicked twice
        let selectedItem = (props.selectedItem && visibleItems[index].id === props.selectedItem.id)?null:visibleItems[index]
        props.selectionChanged(event, selectedItem)
    } */

    /*const visibleItems = items .filter((item) => {
        const regex = new RegExp(searchQuery, "i")
        return regex.test(item.title) || regex.test(item.detail)
    }) */

    //const itemCount = items.length && hasNextPage ? items.length + 1 : items.length;
    return (
        <div className="products-grid">
            <div className="products-grid--topbar">
                <Space>
                    <SearchBox id={id} searchValue={searchValue} handleChange={handleChange} isLoading={isPending} />
                    <TreeSelect
                        value={category}
                        style={{ width: '400px' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={categories}
                        placeholder="Category"
                        onChange={onCategoryChange}
                        allowClear
                        showSearch
                        size="large"
                    />
                    {counting && <Radio.Group
                        options={[
                            {
                                label: `${counting?.total !== undefined ? `All (${counting.total})` : "All"}`,
                                value: null,
                            },
                            {
                                label: `${counting?.published !== undefined ? `Published (${counting.published})` : "Published"}`,
                                value: `publish`
                            },
                            {
                                label: `${counting?.managed !== undefined ? `Managed (${counting.managed})` : "Managed"}`,
                                value: `managed`
                            },
                            {
                                label: `${counting?.updated !== undefined ? `API Update (${counting.updated})` : "API Update"}`,
                                value: `updated`
                            },
                            {
                                label: `${counting?.noprinting !== undefined ? `Unprinted (${counting.noprinting})` : "Unprinted"}`,
                                value: `noprinting`
                            }
                        ]}
                        optionType="button"
                        buttonStyle="solid" value={filterStatus}
                        onChange={e => handleStatusFilterChange(e.target.value)}
                    />}
                </Space>
            </div>
            <div className="products-grid cards">
                {!loading || list.length > 0 ? list.map((item, index) => {
                    return <Card key={index} product={item} supplier={supplier} />
                }) : <CardSkeleton count={10} />}
                {error && <p>Error!</p>}
                {(!loading || list.length > 0) && <div ref={loader} ></div>}
            </div>
        </div>
    )
} 